/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2021-07-08 16:54:32
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-08-05 17:30:35
 */

import { enumToOption } from '@/utils'

// import { enumToOption } from '@/utils/index'
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 的高度
    height: null,
    // table 是否为斑马纹
    stripe: true,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '编号',
    // table index索引属性或方法 number, Function(index) 注意可以赋值 number 或是传入一个 function
    indexMethod: 1,
    // table 工具设置选项 配置则默认开启
    tool: null,
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 100,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'satisfaction',
      label: '满意度',
      disabledHidden: true,
      width: '140',
      slot: 'satisfaction'
    },
    {
      key: 'content',
      label: '反馈',
      disabledHidden: true,
      width: '200'
    },
    {
      key: 'creatorName',
      label: '账号',
      width: '130'
    },
    {
      key: 'contactInformation',
      label: '联系方式',
      width: '140'
    },
    {
      key: 'companyName',
      label: '所属企业',
      width: '180'
    },
    {
      key: 'createTime',
      label: '反馈时间',
      width: '140'
    },
    {
      key: 'action',
      label: '操作',
      width: '140',
      fixed: 'right',
      disabledHidden: true,
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}

// 满意度
export enum satisfactionEnum {
  非常满意 = 1,
  满意,
  一般,
  不满意,
  非常不满意
}
// 页面搜索和基础变量
export const getIndexConfig = () => {
  // 搜索变量
  const inputList = [
    {
      type: 'select',
      label: '满意度',
      key: 'satisfaction',
      placeholder: '请选择',
      labelWidth: 110,
      inputWidth: 200,
      options: enumToOption(satisfactionEnum),
      rules: {
        type: 'number'
      }
    },
    {
      type: 'input',
      label: '企业名称',
      key: 'searchName',
      placeholder: '请选择',
      labelWidth: 110,
      inputWidth: 200,
      maxlength: 16,
      showWordLimit: true
    }
  ]

  // 页面基础变量
  const page = {
    queryForm: {}, // 搜索参数
    currPage: 1,
    pageSize: 20,
    total: 0,
    visible: false, // 弹框显示
    title: '',
    rowData: null // 单行数据
  }

  return {
    inputList,
    ...page
  }
}
