/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2021-07-08 16:54:24
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-08-05 17:20:21
 */

import service from '@/utils/request'
export const getListAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/operation/feedback/page',
    data: pramas
  })
export const detailAPI = (pramas: any) =>
  service.request({
    method: 'post',
    url: '/admin/operation/feedback/detail',
    data: pramas
  })
