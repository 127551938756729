
import { toResult } from '@/utils/toResult'
import { defineComponent, reactive, toRefs, watchEffect } from 'vue'
import { detailAPI } from './api'
import { satisfactionEnum } from './config'
export default defineComponent({
  props: {
    row: Object,
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      loading: false,
      visible: false,
      formData: {},
      satisfactionEnum
    })

    watchEffect(() => {
      state.visible = props.modelValue
    })

    ;(async () => {
      console.log(props.row)
      state.loading = true
      const [err, data] = await toResult(detailAPI({ id: props.row.id }))
      state.loading = false
      if (err) return
      console.log(data)
      state.formData = data || {}
    })()

    const onClose = () => {
      state.visible = false
      state.formData = {}
      emit('update:modelValue', false)
    }
    return {
      ...toRefs(state),
      onClose
    }
  }
})
